//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dateFormat } from "@/utils/dates";
export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      scrollSettings: {
        suppressScrollX: false,
        suppressScrollY: true,
        wheelPropagation: true
      }
    };
  },
  methods: {
    dateFormat(v) {
      return dateFormat(v);
    }
  }
};
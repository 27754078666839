//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("product/getProductDays", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      let a = [{
        title: "Дата",
        show: true,
        name: "date",
        width: 100,
        type: "date"
      }];
      if (this.reportSettings.mp == "wb") {
        a = a.concat([{
          //title: "Цена",
          title: "Цена с WB кошельком",
          //wb-no-spp
          show: true,
          name: "price",
          type: "money",
          width: 100,
          filter: "numberRange"
        },
        /*
        {
            title: "СПП",
            show: true,
            name: "price_discount_spp",
            type: "percent",
            width: 100,
            filter: "numberRange",
        },
        */
        {
          //title: "Базовая цена",
          title: "Цена",
          //wb-no-spp
          show: true,
          name: "price_spp",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Скидка",
          show: true,
          name: "discount_wb",
          type: "percent",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Старая цена",
          show: true,
          name: "price_old",
          type: "money",
          width: 100,
          filter: "numberRange"
        }]);
      } else {
        a = a.concat([{
          title: "Цена",
          show: true,
          name: "price",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Старая цена",
          show: true,
          name: "price_old",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "Скидка",
          show: true,
          name: "discount",
          type: "percent",
          width: 100,
          filter: "numberRange"
        }]);
      }
      a = a.concat([{
        title: "Текущий остаток (шт)",
        show: true,
        name: "remains",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "Продажи",
        show: true,
        name: "sold",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Выручка",
        show: true,
        name: "revenue",
        type: "money",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Отзывы",
        show: true,
        name: "reviews",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Новые Отзывы",
        show: true,
        name: "new_reviews_1day",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Рейтинг",
        show: true,
        name: "rating",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Кол-во категорий",
        show: true,
        name: "categories",
        type: "number",
        width: 100,
        filter: "numberRange"
      }]);
      return a.map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};